import React, { useContext } from 'react'
import styled from 'styled-components'

import FontAwesomeIcon from '../components/fontAwesomeIcon.tsx'

import colors from '../styles/colors'

import { GlobalStateContext } from '../context/GlobalContextProvider'

import 'css.gg/icons/css/loadbar-alt.css'

const FooterWrapper = styled.div`
  text-align: center;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
`

const FooterText = styled.h6`
  color: inherit;
  font-weight: bold;
  text-rendering: optimizeLegibility;
  font-size: 0.78405rem;
  line-height: 1.1;

  margin: 0px;
  padding: 2px;
`

const Icon = styled.i`
  color: blue;
  display: inline-block;
`

const Footer = (props) => {
  const state = useContext(GlobalStateContext)
  const theme = colors[state.theme]

  return (
    <FooterWrapper>
      <FooterText>
        Crafted with{' '}
        <FontAwesomeIcon iconName='heart' iconAccent={theme.heart}/>{' '}
        by Ameya Shenoy</FooterText>
      <FooterText>
        <FontAwesomeIcon iconName='code'/>{' '}
        Powerd by{' '}
        <a
          target="_blank"
          rel="noopener"
          href="https://www.gatsbyjs.org/"
          style={{
            color: `${theme.heart}`
          }}
        >Gatsby</a>{' '}
        and{' '}
        <a
          target="_blank"
          rel="noopener"
          href="https://k3s.io/"
          style={{
            color: `${theme.heart}`
          }}
        >K3s</a>{' '}
      </FooterText>

      <FooterText>
        <FontAwesomeIcon iconName='copyright'/>{' '}
        2020-{(new Date().getFullYear())}
      </FooterText>
      <Icon
        className='gg-loadbar-alt'
        data-tip="It's the little things that matter!"
        data-effect= "solid"
        data-background-color="#212223"
        data-text-color="white"
      ></Icon>
    </FooterWrapper>
  )
}

export default Footer
