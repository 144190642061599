import React, { useContext } from 'react'
import styled from 'styled-components'
import ThemeToggleIcon from '../components/themeToggleIcon'

import { Link, useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

import colors from '../styles/colors'

import { GlobalStateContext } from '../context/GlobalContextProvider'

const NavBarWrapper = styled.nav`
  display: flex;
  flex-direction: row;
  align-items: stretch;

  width: 1100px;
  @media only screen and (max-width: 1500px) {
    width: 90%;
  }

  padding-top: 1.5rem;

  font-weight: 500;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
`

const StyledLink = styled(Link)`
  outline: 0;
  -moz-outline-style: none;
  color: ${props => props.theme.text};


  background-color: ${props => props.theme.background};
  transition: color 0.2s ease-out, background 0.2s ease-out;
  text-transform: uppercase;
`

let navLeft = <></>
let navCenter = <></>

const NavBar = (props) => {
  const state = useContext(GlobalStateContext)
  const theme = colors[state.theme]

  const data = useStaticQuery(graphql`
    query {
      avatar: file(relativePath: { eq: "avatar.png" }) {
        childImageSharp {
          fluid(maxWidth: 26) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
    }
  `)

  if (typeof window !== 'undefined') {
    navLeft = <>{window.location.pathname !== '/' ? <Img fluid={data.avatar.childImageSharp.fluid}/> : <>codingcoffee</>}</>
    navCenter = <>{window.location.pathname !== '/' ? <ThemeToggleIcon/> : <></>}</>
  }

  return (
    <NavBarWrapper>
      <StyledLink
        theme={theme}
        style={{
          flex: 1,
          textAlign: 'left'
        }}
        to='/'
      >
        {navLeft}
      </StyledLink>

      <div
        style={{
          outline: 0,
          flex: 1,
          textAlign: 'center'
        }}
      >
        <div>
          {navCenter}
        </div>
      </div>

      <StyledLink
        theme={theme}
        style={{
          flex: 1,
          textAlign: 'right'
        }}
        to='/blog'
      >
        blog
      </StyledLink>

    </NavBarWrapper>
  )
}

export default NavBar
