import React from 'react'
import PropTypes from 'prop-types'

const FontAwesomeIcon = (props) => {
  return (
    <i
      className={`fa fa-${props.iconName} fa-${props.iconSize || 1}x`}
      aria-hidden="true"
      style={{
        color: `${props.iconAccent}`
      }}
    />
  )
}

FontAwesomeIcon.propTypes = {
  iconName: PropTypes.string.isRequired,
  iconSize: PropTypes.number,
  iconAccent: PropTypes.string
}

export default FontAwesomeIcon
