import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import NavBar from '../components/navBar'
import Footer from '../components/footer'
import '../styles/layout.css'

import colors from '../styles/colors'

import { GlobalStateContext } from '../context/GlobalContextProvider'

const Body = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  transition: color 0.2s ease-out, background 0.2s ease-out;
  min-height: 100vh;
`

const Container = styled.div`
  max-width: 1100px;
  margin: auto;
`

const Layout = ({ children }) => {
  const state = useContext(GlobalStateContext)
  const theme = colors[state.theme]

  return (
    <Body
      style={{
        color: `${theme.text}`,
        backgroundColor: `${theme.background}`
      }}
    >
      <NavBar/>

      <Container>
        {children}
      </Container>

      <Footer/>
    </Body>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired
}

export default Layout
