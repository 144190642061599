import React, { useContext } from 'react'
import styled from 'styled-components'

import { GlobalDispatchContext, GlobalStateContext } from '../context/GlobalContextProvider'

import 'css.gg/icons/css/sun.css'
import 'css.gg/icons/css/moon.css'

const ThemeToggleWrapper = styled.i`
  display: inline-block;
  margin-bottom: 1.45rem;
`

const ThemeToggleIcon = (props) => {
  const dispatch = useContext(GlobalDispatchContext)
  const state = useContext(GlobalStateContext)

  return (
    <ThemeToggleWrapper
      className={state.theme === 'light' ? 'gg-moon' : 'gg-sun'}
      onClick={() => {
        dispatch({ type: 'TOGGLE_THEME' })
      }}
    ></ThemeToggleWrapper>
  )
}

export default ThemeToggleIcon
